<template>
  <el-dialog v-model="show" :show-close="false" title="选择链接">
    <el-tabs :tab-position="tabPosition" v-model="className" @tab-click="handleBigTabClick">
      <el-tab-pane label="机构链接" name="content">
        <!-- tab切换 -->
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
          <el-tab-pane label="商务应用功能" name="minipath">
            <vab-query-form>
              <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryMiniPath" @submit.prevent>
                  <el-form-item label="类型">
                    <el-select v-model="queryMiniPath.type" @change="selectMinipath">
                      <el-option v-for="item in minipathType" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item>
                    <el-button :icon="Search" type="primary" @click="selectMinipath">
                      查询
                    </el-button>
                  </el-form-item> -->
                </el-form>
              </vab-query-form-left-panel>
            </vab-query-form>
            <el-table ref="miniPathTable" border :data="miniPathData" :row-key="getRowKeys" @select="handleSelectionChangeByMiniPath">
              <el-table-column type="selection" width="55" />
              <el-table-column align="center" label="页面名称" prop="name" show-overflow-tooltip />
              <el-table-column align="center" label="页面路径" prop="router" show-overflow-tooltip />
              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="机构页面" name="page">
            <vab-query-form>
              <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryForm2" @submit.prevent>
                  <el-form-item label="页面标题">
                    <el-input v-model.trim="queryForm2.name" clearable placeholder="请输入名称" />
                  </el-form-item>
                  <el-form-item>
                    <el-button :icon="Search" type="primary" @click="queryPlatePageData">
                      查询
                    </el-button>
                  </el-form-item>
                </el-form>
              </vab-query-form-left-panel>
            </vab-query-form>

            <el-table ref="pageTable" v-loading="listLoading2" border :data="pageList" :row-key="getRowKeys" @select="handleSelectionChangeByPage">
              <el-table-column type="selection" width="55" />

              <el-table-column align="center" label="页面标题" prop="name" show-overflow-tooltip />
              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
            <el-pagination background :current-page="queryForm2.pageNumber" :layout="layout" :page-size="queryForm2.pageSize" :total="total3" @current-change="handleCurrentChangePage" @size-change="handleSizeChangePage" />
          </el-tab-pane>

          <el-tab-pane label="展会页面" name="exhPage" v-if="meetingCode">
            <vab-query-form>
              <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryFormExhPage" @submit.prevent>
                  <el-form-item label="页面标题">
                    <el-input v-model.trim="queryFormExhPage.name" clearable placeholder="请输入名称" />
                  </el-form-item>
                  <el-form-item>
                    <el-button :icon="Search" type="primary" @click="queryExhPageData">
                      查询
                    </el-button>
                  </el-form-item>
                </el-form>
              </vab-query-form-left-panel>
            </vab-query-form>

            <el-table ref="exhPageTable" v-loading="listLoadingExhPage" border :data="exhPageList" :row-key="getRowKeys" @select="handleSelectionChangeByExhPage">
              <el-table-column type="selection" width="55" />

              <el-table-column align="center" label="页面标题" prop="name" show-overflow-tooltip />
              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
            <el-pagination background :current-page="queryFormExhPage.pageNumber" :layout="layout" :page-size="queryFormExhPage.pageSize" :total="exhPageTotal" @current-change="handleCurrentChangeExhPage" @size-change="handleSizeChangeExhPage" />
          </el-tab-pane>

          <el-tab-pane label="会议页面" name="holdMeet">
            <vab-query-form>
              <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryFormByHoldMeet" @submit.prevent>
                  <el-form-item label="会议名称">
                    <el-input v-model.trim="queryFormByHoldMeet.likeName" clearable placeholder="请输入名称" />
                  </el-form-item>
                  <el-form-item>
                    <el-button :icon="Search" type="primary" @click="queryHoldMeet">
                      查询
                    </el-button>
                  </el-form-item>
                </el-form>
              </vab-query-form-left-panel>
            </vab-query-form>
            <el-table ref="holdMeetTable" v-loading="listLoading5" :height="500" border :data="holdMeetList" :row-key="getRowKeys" @select="handleSelectionChangeByHoldMeet">
              <el-table-column type="selection" width="55" />
              <el-table-column fixed label="会议名称" prop="name" show-overflow-tooltip />
              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="内容页面" name="Article">
            <el-table ref="articleTable" v-loading="listLoading3" border :data="articleList" :row-key="getRowKeys" @select="handleSelectionChangeByArticle">
              <el-table-column type="selection" width="55" />
              <el-table-column fixed label="内容标题" prop="title" show-overflow-tooltip />

              <el-table-column fixed label="内容类型" prop="articleCategoryName" show-overflow-tooltip />

              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
            <el-pagination background :current-page="queryForm3.pageNum" :layout="layout" :page-size="queryForm3.pageSize" :total="total2" @current-change="handleCurrentChangeArticle" @size-change="handleSizeChangeArticle" />
          </el-tab-pane>

        </el-tabs>

      </el-tab-pane>
      <el-tab-pane label="平台链接" name="plantForm">
        <!-- tab切换 -->
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
          <el-tab-pane label="关联机构" name="enterprise">
            <vab-query-form>
              <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryFormByEnterprise" @submit.prevent>
                  <el-form-item label="机构名称">
                    <el-input v-model.trim="queryFormByEnterprise.likeName" clearable placeholder="请输入名称" />
                  </el-form-item>
                  <el-form-item>
                    <el-button :icon="Search" type="primary" @click="queryEnterprise">
                      查询
                    </el-button>
                  </el-form-item>
                </el-form>
              </vab-query-form-left-panel>
            </vab-query-form>
            <el-table ref="enterpriseTable" v-loading="listLoading6" :height="500" border :data="enterpriseList" :row-key="getRowKeys" @select="handleSelectionChangeByEnterprise">
              <el-table-column type="selection" width="55" />
              <el-table-column fixed label="机构名称" prop="name" show-overflow-tooltip />
              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="店铺" name="shop">
            <vab-query-form>
              <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryForm" @submit.prevent>
                  <el-form-item label="店铺名称">
                    <el-input v-model.trim="queryForm.shopName" clearable placeholder="请输入名称" />
                  </el-form-item>
                  <el-form-item>
                    <el-button :icon="Search" type="primary" @click="queryShopData">
                      查询
                    </el-button>
                  </el-form-item>
                </el-form>
              </vab-query-form-left-panel>
            </vab-query-form>
            <el-table ref="shopTable" v-loading="listLoading" border :data="list" :row-key="getRowKeys" style="width: 100%" @select="handleSelectionChangeByShop">
              <el-table-column type="selection" width="55" />
              <el-table-column label="店铺名称" prop="shopName" show-overflow-tooltip />
              <el-table-column label="联系电话1" prop="shopContactNumber" show-overflow-tooltip />
              <el-table-column label="联系地址" prop="shopContactNumber" show-overflow-tooltip>
                <template #default="{ row }">
                  {{ row.shopProvince }} - {{ row.shopCity }} -
                  {{ row.shopArea }} -
                  {{ row.shopAddress }}
                </template>
              </el-table-column>
              <el-table-column label="营业时间" prop="shopBusinessTime" show-overflow-tooltip />

              <el-table-column label="状态" prop="status" show-overflow-tooltip>
                <template #default="{ row }">
                  <el-switch v-model="row.status" active-text="上线" disabled inactive-text="下线" size="large" @change="shopStatusChange(row.id, row.status)" />
                </template>
              </el-table-column>
              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
            <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChangeShop" @size-change="handleSizeChangeShop" />
          </el-tab-pane>
          <el-tab-pane label="活动" name="active">
            <vab-query-form>
              <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryForm1" @submit.prevent>
                  <el-form-item label="活动名称">
                    <el-input v-model.trim="queryForm1.title" clearable placeholder="请输入名称" />
                  </el-form-item>
                  <el-form-item>
                    <el-button :icon="Search" type="primary" @click="queryActiveData">
                      查询
                    </el-button>
                  </el-form-item>
                </el-form>
              </vab-query-form-left-panel>
            </vab-query-form>

            <el-table ref="acitveTable" v-loading="listLoading1" border :data="activeList" :row-key="getRowKeys" @select="handleSelectionChangeByActive">
              <el-table-column type="selection" width="55" />

              <el-table-column fixed label="活动标题" prop="title" show-overflow-tooltip />
              <el-table-column fixed label="开始时间" show-overflow-tooltip>
                <template #default="{ row }">
                  <span>{{ handleDate(row.startTime) }}</span>
                </template>
              </el-table-column>

              <el-table-column fixed label="结束时间" show-overflow-tooltip>
                <template #default="{ row }">
                  <span>{{ handleDate(row.endTime) }}</span>
                </template>
              </el-table-column>

              <el-table-column label="活动地址" prop="address" show-overflow-tooltip />
              <el-table-column label="主办方" prop="organisers" show-overflow-tooltip />
              <el-table-column label="活动人数" prop="peopleNum" show-overflow-tooltip />
              <el-table-column label="活动亮点" prop="sparkle" show-overflow-tooltip />
              <template #empty>
                <el-empty class="vab-data-empty" description="暂无数据" />
              </template>
            </el-table>
            <el-pagination background :current-page="queryForm1.pageNum" :layout="layout" :page-size="queryForm1.pageSize" :total="total1" @current-change="handleCurrentChangeActive" @size-change="handleSizeChangeActive" />
          </el-tab-pane>

        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="第三方链接" name="thirdLink">
        <!-- tab切换 -->
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
          <el-tab-pane v-if="!multFlag" label="外部链接" name="link">
            <el-form>
              <el-form-item label="H5链接地址">
                <el-input v-model="urlLink" placeholder="请输入需要跳转的h5链接" />
                <div style="font-size: 14px; color: red">
                  * 链接地址需要配置在小程序白名单内，请确认后输入
                </div>
              </el-form-item>

              <el-form-item label="是否需要注册">
                <el-radio-group v-model="needToken">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>

            </el-form>
          </el-tab-pane>

          <el-tab-pane v-if="!multFlag" label="小程序" name="mini">
            <el-form>
              <el-form-item label="小程序名称">
                <el-input v-model="miniData.name" placeholder="请输入小程序名称" />
              </el-form-item>

              <el-form-item label="小程序appId">
                <el-input v-model="miniData.appId" placeholder="请输入小程序appId" />
              </el-form-item>
              <el-form-item label="路径地址">
                <el-input v-model="miniData.path" placeholder="请输入路径地址" />
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <el-tab-pane v-if="!multFlag" label="视频号" name="wxVideo">
            <el-form>
              <el-form-item label="跳转类型">
                <el-radio-group v-model="wxVideoType.type" class="ml-4">
                  <el-radio label="homePage" size="large">视频号首页</el-radio>
                  <el-radio label="detail" size="large">视频详情页</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="视频号Id">
                <el-input v-model="wxVideoType.finderUserName" placeholder="请输入需要跳转的视频号 id，以“sph”开头的id，可在视频号助手获取" />
              </el-form-item>
              <el-form-item v-if="wxVideoType.type == 'detail'" label="视频Id">
                <el-input v-model="wxVideoType.eventId" placeholder="请输入需要跳转的视频活动 id，可在视频号助手获取" />
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>

      <el-tab-pane label="暂不跳转" name="notopen">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
          <el-tab-pane label="暂不跳转" name="notopen">
            <el-form>
              <el-form-item label="暂不跳转">
                <el-switch v-model="notOpenFlag" />
              </el-form-item>

              <el-form-item label="提示文案">
                <el-input v-model="notOpenMes" placeholder="请输入提示文案" />
              </el-form-item>

            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>

    </el-tabs>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmChoose">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  onActivated,
  toRefs,
  nextTick,
  watch,
} from 'vue'
import { getShopList } from '@/api/shop'
import { activeList } from '@/api/active'
import { getPage } from '@/api/page'
import { getArticleList } from '@/api/content'
import { exShelfAndUnshelfList } from '@/api/exhibitionManage'
import { Search } from '@element-plus/icons'
import { parseTime } from '@/utils/index'
import { getPlateAllData } from '@/api/workBench'
import { getAllEnterpriseListByName } from '@/api/enterPriseApi'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'chooseLink.vue',

  props: {
    multChoose: {
      type: Boolean,
      default: false,
    },
    enterPriseCode: {
      default: '',
      type: String,
    },
    linkEnterpriseCode: {
      default: '',
      type: String,
    }
  },
  emits: ['chooseLink'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const state = reactive({
      activeName: 'minipath',
      className: 'content',
      tabPosition: 'left',
      queryForm: { shopName: '', pageNumber: 1, pageSize: 10 },
      queryForm1: { title: '', pageNum: 1, pageSize: 10 },
      queryForm2: { name: '', pageNumber: 1, pageSize: 10 },
      queryFormExhPage: {
        name: '',
        pageNumber: 1,
        pageSize: 10,
        meetingCode: route.query.meetingCode,
      },
      queryForm3: { pageNum: 1, pageSize: 10, status: 'PUBLISH' },
      queryFormByHoldMeet: { meetingType: 'HOLD_MEETING', likeName: '' },
      queryFormByEnterprise: { meetingType: 'HOLD_MEETING', likeName: '' },
      queryForm4: {
        isShelf: '',
        meetingName: '',
        pageNum: 1,
        pageSize: 9999,
        status: '',
      },
      total: 0,
      total1: 0,
      total2: 0,
      total3: 0,
      exhPageTotal: 0,
      listLoading: false,
      listLoading1: false,
      listLoading2: false,
      listLoading3: false,
      listLoading4: false,
      listLoading5: false,
      listLoading6: false,
      list: [],
      activeList: [],
      articleList: [],
      queryMiniPath: {
        type: '',
      },
      miniPathData: [],
      minipathType: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'exh',
          label: '展会功能',
        },
        {
          value: 'enterprise',
          label: '机构功能',
        },
        {
          value: 'other',
          label: '其他功能',
        },
      ],
      miniPathList: [
        {
          id: 4,
          name: '观众注册',
          router: 'subPackage/exhibition/customer-exhibition/apply-form',
          type: 'miniPath',
          extraParams: {
            identity: 'AUDIENCE',
          },
          pathType: 'exh',
        },
        {
          id: 4,
          name: '会员注册',
          router: 'subPackage/exhibition/customer-exhibition/apply-form',
          type: 'miniPath',
          extraParams: {
            identity: 'MEMBER',
          },
          pathType: 'exh',
        },
        {
          id: 5,
          name: '媒体注册',
          router: 'subPackage/exhibition/customer-exhibition/apply-form',
          type: 'miniPath',
          extraParams: {
            identity: 'MEDIA',
          },
          pathType: 'exh',
        },

        {
          id: 19,
          name: '机构注册',
          router: 'subPackage/exhibition/customer-exhibition/apply-form',
          type: 'miniPath',
          extraParams: {
            identity: 'EXHIBITOR',
          },
          pathType: 'exh',
        },
        {
          id: 21,
          name: '活动合作方注册',
          router: 'subPackage/exhibition/customer-exhibition/apply-form',
          type: 'miniPath',
          extraParams: {
            identity: 'ACTIVE',
          },
          pathType: 'exh',
        },
        {
          id: 23,
          name: '应用动态',
          router: 'subPackage/social/pages/user-trends/meet-dynamic',
          type: 'miniPath',
          pathType: 'exh',
        },
        {
          id: 8,
          name: '门票购买',
          router: 'subPackage/admission-ticket/buy/exhibition-ticket',
          type: 'miniPath',
          pathType: 'exh',
        },
        {
          id: 15,
          name: '机构列表',
          router: 'subPackage/customPage/exhibitor/exhibitorList',
          type: 'miniPath',
          pathType: 'exh',
        },
        {
          id: 17,
          name: '参会列表',
          router: 'subPackage/meet/holdMeet/all-attend',
          type: 'miniPath',
          pathType: 'exh',
        },
        {
          id: 20,
          name: '商机配对',
          router:
            'subPackage/customPage/exhibition/business/filter-index/index ',
          type: 'miniPath',
          pathType: 'exh',
        },
        {
          id: 16,
          name: '应用产品列表',
          router: 'subPackage/customPage/exhibitor/exhibitsList',
          type: 'miniPath',
          pathType: 'exh',
        },

        {
          id: 18,
          name: '应用产品列表-展会',
          router:
            'subPackage/customPage/exhibitor/exhibitsList?type=EXHIBITION',
          type: 'miniPath',
          pathType: 'exh',
        },
        {
          id: 21,
          name: '应用产品列表-场馆',
          router: 'subPackage/customPage/exhibitor/exhibitsList?type=VENUES',
          type: 'miniPath',
          pathType: 'exh',
        },
        {
          id: 22,
          name: '应用产品列表-招聘',
          router: 'subPackage/customPage/exhibitor/exhibitsList?type=WORK',
          type: 'miniPath',
          pathType: 'exh',
        },

        {
          id: 1,
          name: '活动列表',
          // router: 'pages/index-activity/index-activity',
          router: 'subPackage/shops/pages/index-activity/index',
          type: 'miniPath',
          pathType: 'other',
        },
        {
          id: 2,
          name: '店铺列表',
          // router: 'pages/index-shop/index-shop',
          router: 'subPackage/shops/pages/index-shop/index',
          type: 'miniPath',
          pathType: 'other',
        },
        {
          id: 3,
          name: '展会列表',
          router: 'subPackage/index/index-page-list/index-page-list',
          type: 'miniPath',
          pathType: 'other',
        },

        {
          id: 6,
          name: '论坛列表',
          router: 'subPackage/exhibition/customer-exhibition/forum-list',
          type: 'miniPath',
          pathType: 'exh',
        },
        // {
        //   id: 7,
        //   name: '展商列表H5',
        //   router: 'subPackage/index/webview/webview-h5 ',
        //   type: 'miniPath',
        //   extraParams: {
        //     type: 'H5',
        //     webviewUrl: '/exhibitor/exhibitorList',
        //   },
        //   pathType: 'other',
        // },
        // {
        //   id: 11,
        //   name: '展品列表H5',
        //   router: 'subPackage/index/webview/webview-h5',
        //   type: 'miniPath',
        //   extraParams: {
        //     type: 'H5',
        //     webviewUrl: '/exhibitor/exhibitsList',
        //   },
        //   pathType: 'other',
        // },

        {
          id: 11,
          name: '机构活动',
          router: `subPackage/enterprise/pages/exhibition-plan/index?enterpriseCode=${props.linkEnterpriseCode}`,
          type: 'miniPath',
          pathType: 'enterprise',
        },
        {
          id: 12,
          name: '机构动态',
          router: `subPackage/social/pages/enterprise-trends/index?enterpriseCode=${props.linkEnterpriseCode}&navTitle=机构动态`,
          type: 'miniPath',
          pathType: 'enterprise',
        },

        {
          id: 13,
          name: '机构入驻',
          router: 'subPackage/enterprise/pages/edit-page/index',
          type: 'miniPath',
          pathType: 'other',
        },
        {
          id: 40,
          name: '机构产品列表',
          router: `subPackage/enterprise/pages/product-list/all?enterpriseCode=${props.linkEnterpriseCode}`,
          type: 'miniPath',
          pathType: 'enterprise',
        },
        {
          id: 43,
          name: '团队介绍',
          router: `subPackage/customPage/enterprise/group/index?enterpriseCode=${props.linkEnterpriseCode}`,
          type: 'miniPath',
          pathType: 'enterprise',
        },
        {
          id: 44,
          name: '机构介绍',
          router: `subPackage/account/enterprise/enterpriseIntro/index?enterpriseCode=${props.linkEnterpriseCode}`,
          type: 'miniPath',
          pathType: 'enterprise',
        },
        {
          id: 45,
          name: '机构视频',
          router: `subPackage/account/enterprise/enterpriseVideo/index?enterpriseCode=${props.linkEnterpriseCode}`,
          type: 'miniPath',
          pathType: 'enterprise',
        },
        {
          id: 46,
          name: '机构资讯',
          router: `subPackage/account/enterprise/enterpriseInfo/index?enterpriseCode=${props.linkEnterpriseCode}`,
          type: 'miniPath',
          pathType: 'enterprise',
        },
        {
          id: 47,
          name: '联系我们',
          router: `subPackage/account/enterprise/linkUs/index?enterpriseCode=${props.linkEnterpriseCode}`,
          type: 'miniPath',
          pathType: 'enterprise',
        },
      ],
      exhList: [],
      exhPageList: [],
      holdMeetList: [],
      enterpriseList: [],
      layout: 'prev, pager, next',
      chooseVal: [],
      show: false,
      acitveTable: null,
      shopTable: null,
      pageTable: null,
      exhTable: null,
      holdMeetTable: null,
      articleTable: null,
      miniPathTable: null,
      exhPageTable: null,
      enterpriseTable: null,
      multFlag: false,

      // 当前选中id
      echoList: [],
      // 记录值
      alreadyChoose: [],
      pageList: [],
      urlLink: '', // url 选择
      needToken: false,
      miniData: {
        appId: '',
        path: '',
        name: '',
      },
      wxVideoType: {
        finderUserName: '',
        eventId: '',
        type: 'homePage',
      },
      meetingCode: route.query.meetingCode || undefined,
      notOpenMes: '敬请期待',
      notOpenFlag: false,
    })

    // tab切换,回显默认数据
    const handleTabClick = (tab) => {
      // state.chooseVal = []
      state.echoList = []
      // resetRowSelection()
      console.log(tab.props.name)
      tabChangeChoose(state.alreadyChoose, tab.props.name)
    }

    watch(
      () => props.multChoose,
      (count, prevCount) => {
        state.multFlag = count
      }
    )

    const showDialog = (val) => {
      console.log(val, '======>>>>')
      state.chooseVal = val
      state.alreadyChoose = JSON.parse(JSON.stringify(val))
      state.activeName = 'minipath'
      state.show = true
      state.miniPathData = JSON.parse(JSON.stringify(state.miniPathList))
      console.log(props.multChoose)

      // 判断是否选择过，选择回显
      console.log(val, 'asdasd')
      if (val.length > 0) {
        showHasChoose(val)
      } else {
        resetRowSelection()
      }
    }

    // 清空多选数据源
    const resetRowSelection = () => {
      nextTick(() => {
        state.activeList.forEach((item) => {
          state.acitveTable.toggleRowSelection(item, false)
        })
        state.list.forEach((item) => {
          state.shopTable.toggleRowSelection(item, false)
        })
        state.pageList.forEach((item) => {
          if (state.pageTable) {
            state.pageTable.toggleRowSelection(item, false)
          }
        })
        state.exhPageList.forEach((item) => {
          if (state.exhPageTable) {
            state.exhPageTable.toggleRowSelection(item, false)
          }
        })
        state.articleList.forEach((item) => {
          state.articleTable.toggleRowSelection(item, false)
        })
        state.miniPathList.forEach((item) => {
          state.miniPathTable.toggleRowSelection(item, false)
        })
        state.exhList.forEach((item) => {
          state.exhTable.toggleRowSelection(item, false)
        })
      })
    }

    const closeDialog = () => {
      state.show = false
      resetRowSelection()
    }

    /**
     * 展示回显数据
     * */
    const showHasChoose = (val) => {
      state.echoList = val.map((item) =>
        item.type == val[0].type ? item.id || item.code : ''
      )
      // 根据类型判断，单选多选只有一种类型，根据type 字段判断
      if (val[0].type == 'active') {
        state.activeName = 'active'
        state.className = 'plantForm'
        nextTick(() => {
          state.activeList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.acitveTable.toggleRowSelection(item, true)
            } else {
              state.acitveTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (val[0].type == 'shop') {
        state.activeName = 'shop'
        state.className = 'plantForm'
        nextTick(() => {
          state.list.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.shopTable.toggleRowSelection(item, true)
            } else {
              state.shopTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (val[0].type == 'Article') {
        state.activeName = 'Article'
        state.className = 'content'
        nextTick(() => {
          state.articleList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.articleTable.toggleRowSelection(item, true)
            } else {
              state.articleTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (val[0].type == 'exhibition') {
        state.activeName = 'exhibition'
        state.className = 'content'
        nextTick(() => {
          state.exhList.forEach((item) => {
            if (state.echoList.includes(item.code)) {
              state.exhTable.toggleRowSelection(item, true)
            } else {
              state.exhTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (val[0].type == 'holdMeet') {
        state.activeName = 'holdMeet'
        state.className = 'content'
        nextTick(() => {
          state.holdMeetList.forEach((item) => {
            if (state.echoList.includes(item.code)) {
              state.holdMeetTable.toggleRowSelection(item, true)
            } else {
              state.holdMeetTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (val[0].type == 'page') {
        state.activeName = 'page'
        state.className = 'content'
        nextTick(() => {
          state.pageList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.pageTable.toggleRowSelection(item, true)
            } else {
              state.pageTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (val[0].type == 'exhPage') {
        state.activeName = 'exhPage'
        state.className = 'content'
        nextTick(() => {
          state.exhPageList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.exhPageTable.toggleRowSelection(item, true)
            } else {
              state.exhPageTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (val[0].type == 'miniPath') {
        state.activeName = 'miniPath'
        state.className = 'content'
        nextTick(() => {
          state.miniPathList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.miniPathTable.toggleRowSelection(item, true)
            } else {
              state.miniPathTable.toggleRowSelection(item, false)
            }
          })
        })
      }
      if (val[0].type == 'link') {
        console.log(val[0], `/v/v/v/v/v/`)
        state.activeName = 'link'
        state.className = 'thirdLink'
        state.urlLink = val[0].url
        state.needToken = val[0].extraParams.needToken
      }
      if (val[0].type == 'mini') {
        state.activeName = 'mini'
        state.className = 'thirdLink'
        state.miniData = val[0].miniData
      }
      if (val[0].type == 'wxVideo') {
        state.activeName = 'wxVideo'
        state.className = 'thirdLink'
        state.wxVideoType = val[0].wxVideoType
      }
      if (val[0].type == 'notopen') {
        state.activeName = 'notopen'
        state.className = 'notopen'
        state.notOpenMes = val[0].extraParams.notOpenMes
        state.notOpenFlag = val[0].extraParams.notOpenFlag
      }
    }

    /**
     *   tab 切换数据回显
     * */
    const tabChangeChoose = (val, type) => {
      state.echoList = val.map((item) =>
        item.type == type ? item.id || item.code : ''
      )
      console.log(state.echoList)
      // 根据类型判断，单选多选只有一种类型，根据type 字段判断
      if (type == 'active') {
        state.activeName = 'active'
        nextTick(() => {
          state.activeList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.acitveTable.toggleRowSelection(item, true)
            } else {
              state.acitveTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (type == 'shop') {
        state.activeName = 'shop'
        nextTick(() => {
          state.list.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.shopTable.toggleRowSelection(item, true)
            } else {
              state.shopTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (type == 'exhibition') {
        state.activeName = 'exhibition'
        nextTick(() => {
          state.exhList.forEach((item) => {
            if (state.echoList.includes(item.code)) {
              state.exhTable.toggleRowSelection(item, true)
            } else {
              state.exhTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (type == 'holdMeet') {
        state.activeName = 'holdMeet'
        nextTick(() => {
          state.holdMeetList.forEach((item) => {
            if (state.echoList.includes(item.code)) {
              state.holdMeetTable.toggleRowSelection(item, true)
            } else {
              state.holdMeetTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (type == 'Article') {
        state.activeName = 'Article'
        nextTick(() => {
          state.articleList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.articleTable.toggleRowSelection(item, true)
            } else {
              state.articleTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (type == 'page') {
        state.activeName = 'page'
        nextTick(() => {
          state.pageList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.pageTable.toggleRowSelection(item, true)
            } else {
              state.pageTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (type == 'exhPage') {
        state.activeName = 'exhPage'
        nextTick(() => {
          state.exhPageList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.exhPageTable.toggleRowSelection(item, true)
            } else {
              state.exhPageTable.toggleRowSelection(item, false)
            }
          })
        })
      }

      if (type == 'miniPath') {
        state.activeName = 'miniPath'
        nextTick(() => {
          state.miniPathList.forEach((item) => {
            if (state.echoList.includes(item.id)) {
              state.miniPathTable.toggleRowSelection(item, true)
            } else {
              state.miniPathTable.toggleRowSelection(item, false)
            }
          })
        })
      }
      if (type == 'link') {
        state.activeName = 'link'
        state.urlLink = val[0]?.url
      }
      if (type == 'mini') {
        state.activeName = 'mini'
        state.miniData = val[0]?.miniData ? val[0]?.miniData : {}
      }

      if (type == 'wxVideo') {
        state.activeName = 'wxVideo'
        state.wxVideoType = val[0]?.wxVideoType ? val[0]?.wxVideoType : {}
      }
      if (type == 'notopen') {
        state.activeName = 'notopen'
        state.notOpenMes = val[0].extraParams?.notOpenMes
        state.notOpenFlag = val[0].extraParams?.notOpenFlag
      }
    }

    const getShopData = async () => {
      state.listLoading = true
      const { data } = await getShopList(state.queryForm)
      state.list = handleType(data.list, 'shop')
      state.total = data.total
      state.listLoading = false
      if (state.echoList.length > 0) {
        state.list.forEach((item) => {
          // 重点： 在当前分页列表中查询与回显数据是否有一致的id，一致则勾选数据回显
          // toggleRowSelection(item, true)：设置当前行数据为选中状态
          if (state.echoList.includes(item.id)) {
            state.shopTable.toggleRowSelection(item, true)
          }
        })
      }
    }

    // 店铺选择
    const handleSelectionChangeByShop = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.id)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.id)
          state.chooseVal.push(row)
        }

        state.list.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.shopTable.toggleRowSelection(item, true)
          } else {
            state.shopTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (!state.echoList.includes(row.id)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.id)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'shop')
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    //  确定选择
    const confirmChoose = () => {
      state.show = false
      if (state.activeName == 'notopen') {
        state.chooseVal = [
          {
            type: 'notopen',
            extraParams: {
              notOpenFlag: state.notOpenFlag,
              notOpenMes: state.notOpenMes,
            },
          },
        ]
      }
      // 如果 类型是url，增加判断
      if (state.activeName == 'link') {
        state.chooseVal = [
          {
            type: 'link',
            url: state.urlLink,
            extraParams: {
              needToken: state.needToken,
            },
          },
        ]
      }
      if (state.activeName == 'mini') {
        state.chooseVal = [{ type: 'mini', miniData: state.miniData }]
      }
      // 视频号类型
      if (state.activeName == 'wxVideo') {
        state.chooseVal = [{ type: 'wxVideo', wxVideoType: state.wxVideoType }]
      }

      if (state.activeName == 'enterprise') {
        state.chooseVal.forEach((v) => {
          console.log(v, '1231231231vvvv')
          v['code'] = v.enterpriseCode
        })
      }

      console.log(state.chooseVal, state.activeName, '123123123')
      state.queryMiniPath.type = ''
      emit('chooseLink', state.chooseVal)
      resetRowSelection()
    }

    const handleSizeChangeShop = (val) => {
      state.queryForm.pageSize = val
      getShopData()
    }
    const handleCurrentChangeShop = (val) => {
      state.queryForm.pageNumber = val
      getShopData()
    }

    const queryShopData = () => {
      getShopData()
    }

    const handleDate = (val) => {
      return parseTime(val)
    }

    // 增加数据格式类型
    const handleType = (val, type1) => {
      console.log(type1)
      let nArr = JSON.parse(JSON.stringify(val))
      for (let i in nArr) {
        nArr[i]['type'] = type1
      }
      console.log(nArr)
      return nArr
    }

    /**
     * 活动相关接口
     * */
    // 获取活动列表
    const getActiveData = async () => {
      state.listLoading1 = true
      const { data } = await activeList(state.queryForm1)
      state.activeList = handleType(data.data, 'active')
      state.total1 = data.total
      state.listLoading1 = false
      if (state.echoList.length > 0) {
        state.activeList.forEach((item) => {
          // 重点： 在当前分页列表中查询与回显数据是否有一致的id，一致则勾选数据回显
          // toggleRowSelection(item, true)：设置当前行数据为选中状态
          if (state.echoList.includes(item.id)) {
            state.acitveTable.toggleRowSelection(item, true)
          }
        })
      }
    }

    const handleSizeChangeActive = (val) => {
      state.queryForm1.pageSize = val
      getActiveData()
    }
    const handleCurrentChangeActive = (val) => {
      state.queryForm1.pageNum = val
      getActiveData()
    }

    const queryActiveData = () => {
      getActiveData()
    }

    const exhibitionData = async () => {
      state.listLoading4 = true
      const { data } = await exShelfAndUnshelfList(state.queryForm4)
      state.exhList = handleType(data.data, 'exhibition')
      state.total1 = data.total
      state.listLoading4 = false
      if (state.echoList.length > 0) {
        state.exhList.forEach((item) => {
          // 重点： 在当前分页列表中查询与回显数据是否有一致的id，一致则勾选数据回显
          // toggleRowSelection(item, true)：设置当前行数据为选中状态
          if (state.echoList.includes(item.code)) {
            state.exhTable.toggleRowSelection(item, true)
          }
        })
      }
    }
    // 展会内容选中table
    const handleSelectionChangeByExhibition = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.code) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.code)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.code)
          state.chooseVal.push(row)
        }
        state.exhList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.exhTable.toggleRowSelection(item, true)
          } else {
            state.exhTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (!state.echoList.includes(row.code)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.code)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'exhibition')
          state.echoList.forEach((id, index) => {
            if (id === row.code) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    // table 选中 活动
    const handleSelectionChangeByActive = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.id)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.id)
          state.chooseVal.push(row)
        }
        state.activeList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.acitveTable.toggleRowSelection(item, true)
          } else {
            state.acitveTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (!state.echoList.includes(row.id)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.id)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'active')
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    const getRowKeys = (row) => {
      return row.id
    }

    // 页面模版相关接口
    // 获取页面列表
    const getPageData = async () => {
      state.listLoading2 = true
      const { data } = await getPage(state.queryForm2)
      state.pageList = handleType(data.list, 'page')
      state.total3 = data.total
      state.listLoading2 = false
      console.log(state.echoList)
      if (state.echoList.length > 0) {
        state.pageList.forEach((item) => {
          // 重点： 在当前分页列表中查询与回显数据是否有一致的id，一致则勾选数据回显
          // toggleRowSelection(item, true)：设置当前行数据为选中状态
          if (state.echoList.includes(item.id)) {
            console.log(item.id)
            state.pageTable.toggleRowSelection(item, true)
          }
        })
      }
    }
    /**
     * @description: 页面模版分页接口
     * @param {*} val
     * @return {*}
     */
    const handleCurrentChangePage = (val) => {
      state.queryForm2.pageNumber = val
      getPageData()
    }
    const handleSizeChangePage = (val) => {
      state.queryForm2.pageSize = val
      getPageData()
    }
    const queryPlatePageData = (val) => {
      getPageData()
    }

    // 页面配置
    const handleSelectionChangeByPage = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.id)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.id)
          state.chooseVal.push(row)
        }
        state.pageList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.pageTable.toggleRowSelection(item, true)
          } else {
            state.pageTable.toggleRowSelection(item, false)
          }
        })
      } else {
        console.log(state.echoList.includes(row.id), 'asdasdasdasd')
        if (!state.echoList.includes(row.id)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.id)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'page')
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    /**
     * @description:展会页面配置
     * @return {*}
     */
    const getexhPageData = async () => {
      state.listLoadingExhPage = true
      const { data } = await getPage(state.queryFormExhPage)
      state.exhPageList = handleType(data.list, 'exhPage')
      state.exhPageTotal = data.total
      state.listLoadingExhPage = false
      if (state.echoList.length > 0) {
        state.exhPageList.forEach((item) => {
          // 重点： 在当前分页列表中查询与回显数据是否有一致的id，一致则勾选数据回显
          // toggleRowSelection(item, true)：设置当前行数据为选中状态
          if (state.echoList.includes(item.id)) {
            console.log(item.id)
            state.exhPageTable.toggleRowSelection(item, true)
          }
        })
      }
    }
    /**
     * @description: 页面模版分页接口
     * @param {*} val
     * @return {*}
     */
    const handleCurrentChangeExhPage = (val) => {
      state.queryFormExhPage.pageNumber = val
      getexhPageData()
    }
    const handleSizeChangeExhPage = (val) => {
      state.queryFormExhPage.pageSize = val
      getexhPageData()
    }
    const queryExhPageData = (val) => {
      getexhPageData()
    }

    // 页面配置
    const handleSelectionChangeByExhPage = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.id)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.id)
          state.chooseVal.push(row)
        }
        state.exhPageList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.exhPageTable.toggleRowSelection(item, true)
          } else {
            state.exhPageTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (!state.echoList.includes(row.id)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.id)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'exhPage')
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    // 小程序页面路径配置
    const handleSelectionChangeByMiniPath = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.id)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.id)
          state.chooseVal.push(row)
        }

        state.miniPathList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.miniPathTable.toggleRowSelection(item, true)
          } else {
            state.miniPathTable.toggleRowSelection(item, false)
          }
        })
      }
    }

    // 文章相关接口
    // 获取文章列表
    const getArticleData = async () => {
      state.listLoading3 = true
      if (!!props.enterPriseCode) {
        state.queryForm3.enterpriseCode = props.enterPriseCode
      }
      const { data } = await getArticleList(state.queryForm3)
      state.articleList = handleType(data.data, 'Article')
      state.listLoading3 = false
      state.total2 = data.total
      if (state.echoList.length > 0) {
        state.articleList.forEach((item) => {
          // 重点： 在当前分页列表中查询与回显数据是否有一致的id，一致则勾选数据回显
          // toggleRowSelection(item, true)：设置当前行数据为选中状态
          console.log(state.echoList.includes(item.id), 'bvvvasdasdas123123')
          if (state.echoList.includes(item.id)) {
            nextTick(() => {
              state.articleTable.toggleRowSelection(item, true)
            })
          }
        })
      }
    }

    const handleSelectionChangeByArticle = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.id)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.id)
          state.chooseVal.push(row)
        }
        console.log(state.chooseVal, val)
        state.articleList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.articleTable.toggleRowSelection(item, true)
          } else {
            state.articleTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (!state.echoList.includes(row.id)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.id)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'Article')
          state.echoList.forEach((id, index) => {
            if (id === row.id) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    const handleCurrentChangeArticle = (val) => {
      state.queryForm3.pageNum = val
      getArticleData()
    }
    const handleSizeChangeArticle = (val) => {
      state.queryForm3.pageSize = val
      getArticleData()
    }

    // 处理当前删除数据
    const handleAlreadyData = (row, type) => {
      state.alreadyChoose.forEach((item, index) => {
        if (item.type == type && item.id == row.id) {
          state.alreadyChoose.splice(index, 1)
        }
      })
    }

    /**
     * @description: 获取所有办个会
     * @return {*}
     */
    const getAllMeet = async () => {
      state.listLoading4 = true
      const { data } = await getPlateAllData(state.queryFormByHoldMeet)
      state.holdMeetList = handleType(data, 'holdMeet')
      state.listLoading4 = false
      if (state.echoList.length > 0) {
        state.holdMeetList.forEach((item) => {
          // 重点： 在当前分页列表中查询与回显数据是否有一致的id，一致则勾选数据回显
          // toggleRowSelection(item, true)：设置当前行数据为选中状态
          if (state.echoList.includes(item.id)) {
            state.holdMeetTable.toggleRowSelection(item, true)
          }
        })
      }
    }

    /**
     * @description: 查询办个会
     * @return {*}
     */
    const queryHoldMeet = () => {
      getAllMeet()
    }
    /**
     * @description: table 选中切换
     * @param {*} val
     * @param {*} row
     * @return {*}
     */
    const handleSelectionChangeByHoldMeet = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.code) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.code)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.code)
          state.chooseVal.push(row)
        }
        state.holdMeetList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.holdMeetTable.toggleRowSelection(item, true)
          } else {
            state.holdMeetTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (!state.echoList.includes(row.code)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.code)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'holdMeet')
          state.echoList.forEach((id, index) => {
            if (id === row.code) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    /**
     * @description: 外部tab切换
     * @return {*}
     */
    const handleBigTabClick = (e) => {
      console.log(e, e.props.name)
      if (e.props.name == 'thirdLink') {
        state.activeName = 'link'
      } else if (e.props.name == 'content') {
        state.activeName = 'minipath'
      } else {
        state.activeName = 'enterprise'
      }
    }

    // 其他H5 链接配置内容

    /**
     * @description: 获取所有办个会
     * @return {*}
     */
    const getEnterprise = async () => {
      state.listLoading6 = true
      const { data } = await getAllEnterpriseListByName(
        state.queryFormByEnterprise
      )
      state.enterpriseList = handleType(data, 'enterprise')
      state.listLoading6 = false
    }

    /**
     * @description: 查询办个会
     * @return {*}
     */
    const queryEnterprise = () => {
      getEnterprise()
    }

    /**
     * @description: table 选中切换
     * @param {*} val
     * @param {*} row
     * @return {*}
     */
    const handleSelectionChangeByEnterprise = (val, row) => {
      if (!props.multChoose) {
        if (state.echoList.length > 0) {
          state.echoList.forEach((id, index) => {
            if (id === row.data) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            } else {
              state.echoList = []
              state.chooseVal = []
              state.echoList.push(row.data)
              state.chooseVal.push(row)
            }
          })
        } else {
          state.echoList = []
          state.chooseVal = []
          state.echoList.push(row.data)
          state.chooseVal.push(row)
        }
        state.enterpriseList.forEach((item) => {
          if (val[val.length - 1] === item) {
            state.enterpriseTable.toggleRowSelection(item, true)
          } else {
            state.enterpriseTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (!state.echoList.includes(row.data)) {
          // 回显数据里没有本条，把这条加进来(选中)
          state.echoList.push(row.data)
          state.chooseVal.push(row)
          state.alreadyChoose.push(row)
        } else {
          // 回显数据里有本条，把这条删除(取消选中)
          handleAlreadyData(row, 'enterprise')
          state.echoList.forEach((id, index) => {
            if (id === row.data) {
              state.echoList.splice(index, 1)
              state.chooseVal.splice(index, 1)
            }
          })
        }
      }
    }

    // onActivated(() => {
    getShopData()
    getActiveData()
    getPageData()
    getArticleData()
    exhibitionData()
    getAllMeet()
    getexhPageData()
    // })

    const selectMinipath = () => {
      if (state.queryMiniPath.type == '') {
        state.miniPathData = state.miniPathList
        return false
      }
      let data = state.miniPathList.filter(
        (v) => v.pathType == state.queryMiniPath.type
      )
      console.log(data)
      state.miniPathData = data
    }

    return {
      ...toRefs(state),
      selectMinipath,
      handleCurrentChangeArticle,
      handleSizeChangeArticle,
      handleSelectionChangeByShop,
      handleSizeChangeShop,
      handleCurrentChangeShop,
      confirmChoose,
      showDialog,
      Search,
      queryShopData,
      handleDate,
      handleSizeChangeActive,
      handleCurrentChangeActive,
      queryActiveData,
      handleSelectionChangeByActive,
      getRowKeys,
      handleTabClick,
      closeDialog,
      handleSelectionChangeByPage,
      handleSelectionChangeByArticle,
      handleSelectionChangeByMiniPath,
      tabChangeChoose,
      handleSelectionChangeByExhibition,
      handleSelectionChangeByHoldMeet,
      exhibitionData,
      queryHoldMeet,
      handleBigTabClick,
      handleCurrentChangePage,
      handleSizeChangePage,
      queryPlatePageData,
      queryExhPageData,
      handleCurrentChangeExhPage,
      handleSizeChangeExhPage,
      handleSelectionChangeByExhPage,
      queryEnterprise,
      handleSelectionChangeByEnterprise,
    }
  },
})
</script>
<style lang="scss">
.el-table__header-wrapper .el-checkbox {
  //找到表头那一行，然后把里面的复选框隐藏掉
  display: none;
}

.el-table__fixed-header-wrapper .el-checkbox {
  display: none;
}
</style>
